export function useThemeColor() {
  const Theme = {
    Dark: "dark",
    Light: "light",
  } as const;

  const isDarkMode = useState<boolean>(() => false);

  watch(
    isDarkMode,
    () => {
      if (import.meta.client) {
        if (isDarkMode.value) {
          document.documentElement.classList.add("dark");
        } else {
          document.documentElement.classList.remove("dark");
        }
      }
    },
    { immediate: true },
  );

  return { Theme, isDarkMode };
}
